<template>
  <b-container v-touch:swipe.right="swipeRight">
    <b-row>
      <b-col cols="1"/>
      <b-col>
        <h2 style="margin-top: 4rem; margin-bottom: 1.3rem;">Aufladen</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <img class="image" src="@/assets/pictures/step6.jpg" alt="tutorial" style="width: 100%; height: 100%;"/>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <p style="margin-bottom: 4rem; margin-top: 1.3rem;">Wenn die Ladung der Batterie des E-Bikes nachlässt, lade dieses bitte im Lab des Bahnhofsgebäude auf. </p>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row class="fixed-bottom pageIndicatorPos">
      <b-col cols="2"/>
      <b-col cols="8">
        <PageIndicator route="bikeTutorial" :max="6" :pos="6"/>
      </b-col>
      <b-col cols="2"/>
    </b-row>
    <b-row>
      <NextButton text="Fahren" next @click="$router.push('/bikeUnlock')" />
    </b-row>
  </b-container>
</template>

<script>
import NextButton from '../components/NextButton';
import PageIndicator from '../components/PageIndicator';
  export default {
    name: 'BikeTutorial6',
    components: {
      PageIndicator,
      NextButton,
    },
    methods: {
      swipeRight() {
       this.$router.push('/bikeTutorial/5')
      }
    }
  }
</script>

<style scoped>

.image {
  border-radius: 0.5rem;
}

</style>